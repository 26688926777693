import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {api_url} from "../Common/Variable";
import {Button, Modal} from "react-bootstrap";
import NavigationBar from "../Common/Navbar";
import Spinner from "react-bootstrap/Spinner";
import q1vr from "../Resources/Images/q1vr.png";
import q2vr from "../Resources/Images/q2vr.png";
import q3vr from "../Resources/Images/q3vr.png";
import q4vr from "../Resources/Images/q4vr.png";
import q5vr from "../Resources/Images/q5vr.png";

function StartQuiz() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [lang, setLang] = useState(i18n.language);
    const token = localStorage.getItem("token");
    const authenticate = localStorage.getItem("authenticate");
    const [isLoading, setIsLoading] = useState(true);
    const [quizDetails, setQuizDetails] = useState("");
    const {id, attempt} = useParams();
    const parsedId = parseInt(id, 10);
    const [resumed, setResumed] = useState("resume");
    const [completed, setCompleted] = useState(false);

    const handleCompletedClose = () => {
        setCompleted(false);
    };

    const handleCompletedShow = () => {
        setCompleted(true);
    };

    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language]);

    const TestList = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept-Language", lang);
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(api_url + "get_test_list", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                if (result.data && result.data.length > 0) {
                    if (result.data.find((n) => n.test_id === parsedId)) {
                        const selected_test = result.data.find(
                            (n) => n.test_id === parsedId
                        );
                        setQuizDetails(selected_test);
                    } else {
                        navigate("/dashboard");
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const ResumeTestList = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept-Language", lang);
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(api_url + "get_resume_test_list", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                if (result.data && result.data.length > 0) {
                    if (result.data.find((n) => n.test_id === parsedId)) {
                        const selected_test = result.data.find(
                            (n) => n.test_id === parsedId
                        );
                        setQuizDetails(selected_test);
                    } else {
                        navigate("/dashboard");
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        if (!token && !authenticate && !id) {
            navigate("/");
        } else {
            setIsLoading(false);
            if (!attempt) {
                TestList();
            } else {
                ResumeTestList();
            }
        }
    }, [token, authenticate, lang]);

    const handleQuiz = (id, credits, title) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept-Language", lang);
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formdata = new FormData();
        formdata.append("test_id", id);
        formdata.append("credit_id", credits);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "start_test", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 1) {
                    navigate(
                        `/test/${id}/${result.attempt_id}/${quizDetails["allowed_time(min)"]}/${title}/start/${result.total_questions} `
                    );
                }
            })
            .catch((error) => console.log("error", error));
    };

    const handleResumeQuiz = (id, attempt, title) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept-Language", lang);
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formdata = new FormData();
        formdata.append("attempt_id", attempt);
        formdata.append("test_id", id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "resume_test", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                const timeString = result["elapsed_time(h:i:s)"];
                const minutes = timeStringToMinutes(timeString);
                if (result.status === 1) {
                    if(minutes > 0  || minutes === null){
                    navigate(
                        `/test/${id}/${result.attempt_id}/${minutes}/${title}/${resumed} `
                    );}
                    else{
                        handleCompletedShow(true);
                    }
                }
            })

            .catch((error) => console.log("error", error));
    };

    function timeStringToMinutes(timeString) {
        if (timeString === null || timeString === undefined) {
            return null;
        }

        const [hours, minutes, seconds] = timeString.split(":");
        const totalMinutes =
            parseInt(hours) * 60 + parseInt(minutes) + parseInt(seconds) / 60;
        const wholeMinutes = Math.floor(totalMinutes);
        return wholeMinutes;
    }

    if (isLoading) {
        return (
            <div className="text-center">
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{height: "100vh"}}
                >
                    <Spinner
                        animation="border"
                        role="status"
                        style={{width: "5rem", height: "5rem"}}
                    ></Spinner>
                </div>
            </div>
        );
    }

    return (
        <div>
            <NavigationBar/>
            <div className="start-quiz">
                {quizDetails && (
                    <>
                        <h1>{quizDetails.test_title}</h1>

                        <br/>
                        <div className="test-intro">
                            <p>{quizDetails.test_description}</p>
                        </div>
                        <div className="test-time">
                            <h2>
                                <strong>
                                    {t("allowed time")}
                                    {": "}&nbsp;
                                </strong>
                                {quizDetails["allowed_time(min)"]} {t("minutes")}
                            </h2>
                            <h2>
                                <strong>
                                    {t("accepted percentage")}
                                    {": "}
                                </strong>
                                {quizDetails.accepted_percentage}%
                            </h2>
                        </div>
                        <span className="test-heading">{ t( "instructions" ) }</span>
                        
                        {parsedId === 3 ? (
                            <div className="test-instructions">
                                <p>{t("Please read the instructions with every question very carefully and answer the question at the end of every statement.")}</p>
                                <p>{ t( "Example 1: Which is the odd one out?" ) }</p>
                                <div className="img-container">
                                    <img src={ q1vr } className="img-question" alt="Question 1" />
                                </div>
                                <br />
                                <p>{ t( "Example 2: Which comes next?" ) }</p>
                                <div className="img-container">
                                    <img src={ q2vr } className="img-question2" alt="Question 2" />
                                </div>
                                <br />
                                <p>{ t( "Explanation:" ) }</p>
                                <p>{ t( "In the example 1, the correct answer is B. It is the only curved shape that does not have the straight line so it is out and odd among all other shapes." ) }</p>
                                <p>{ t( "In the example 2, the correct answer is E. The figures are turning and also be seen as shape that is expanding by equal amounts until it becomes full circle. The next step would be for the circle to turn by the same amount as before, or let decrease by the same amount as before. So option E is the correct answer. Although A is the correct shape but there is no reason why it should be shaded. D is not correct because it has turned so much. C is the wrong answer because its size is wrong and has not turned at all." ) }</p>
                            </div>

                        ) : ( parsedId === 6 ?
                                <div className="test-instructions">
                                    <p>{t("This test explores how easily you can 'see' and turn around objects in space. You will be shown a shape in the middle of the page. Below it are five other shapes. Each of these is numbered. You have to decide whether each of the alternatives is identical to the original shape. A shape will be the same as the original if it has been turned over or around. It will not be the same as the original if the proportions or parts have been changed. Answer each question with a 'Y' for Yes and 'N' for No. Remember, shapes that are identical to the original, but have been turned around or have been turned over, are marked 'Y'. If it is not either the same or is not a 'mirror image' you mark 'N'. Try to 'see' the result in your mind.")}</p>
                                    <div className="img-container">
                                        <img src={ q3vr } className="img-question" alt="Question 1" />
                                    </div>
                                    <p>{t("Answers to example items: 1N 2N 3Y 4Y 5N")}</p>
                                </div>
                                :
                                ( parsedId === 7 ? 
                                    <div className="test-instructions">
                                        <p>{ t("In this test you are asked to discover the hidden sides of objects. You are shown a stack of blocks. Each block is exactly the same size. You can see the front or facing sides. You can also see the topside. But you cannot see the two sides that are hidden sides and underneath must look like. In the example on the next page, there is a stack of five blocks. You have to imagine, out of the five possibilities you are given, which one is the view from side A. Only one of the five is correct. Then, do the same for sides B and C. In each set of possibilities, only one is correct, even though it may have been turned around. Write the correct Roman number in answer booklet.")}</p>
                                        <div className="img-container">
                                            <img src={ q4vr } className="img-question3" alt="Question 1" />
                                        </div>
                                        <div className="img-container">
                                            <img src={ q5vr } className="img-question4" alt="Question 1" />
                                        </div>
                                        <p>{ t("For example, the answer is number iii for side A, even though it has been turned around. For side B, number i is the correct answer. Number v is the correct answer for side C. You may find it helpful to have spare paper available. For each question, tick the correct choice out of the five options given for each side. Be careful, as the view may have been turned around.")}</p>
                                    </div>
                                    : 
                                <div
                                className="test-instructions"
                                style={ { whiteSpace: "pre-line" } }
                                dangerouslySetInnerHTML={ { __html: quizDetails.test_instruction } }
                                />
                                )
                            )
                        }       
                        
                        <Button
                            className="quiz-start-btn"
                            onClick={() => {
                                if (!attempt) {
                                    handleQuiz(
                                        quizDetails.test_id,
                                        quizDetails.test_credits[0].id,
                                        quizDetails.test_title
                                    );
                                } else {
                                    handleResumeQuiz(
                                        quizDetails.test_id,
                                        attempt,
                                        quizDetails.test_title
                                    );
                                }
                            }}
                        >
                            {t("start quiz")}
                        </Button>
                    </>
                )}
            </div>
            <Modal show={completed} onHide={handleCompletedClose} centered>
                <Modal.Title>{t("time up")}</Modal.Title>
                <Modal.Body>
                    <Button onClick={handleCompletedClose}>OK</Button>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default StartQuiz;

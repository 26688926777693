import { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { api_url } from "../Common/Variable";
import Fade from "react-reveal/Fade";
import NavigationBar from "../Common/Navbar";
import Footer from "../Common/Footer";

function Dashboard() {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [lang, setLang] = useState(i18n.language);
	const token = localStorage.getItem("token");
	const authenticate = localStorage.getItem("authenticate");
	const [isLoading, setIsLoading] = useState(true);
	const [quiz, setQuiz] = useState("");
	const [resumeQuiz, setResumeQuiz] = useState("");
	const [nTest, setnTest] = useState(false);
	const [rTest, setrTest] = useState(false);

	useEffect(() => {
		setLang(i18n.language);
	}, [i18n.language]);

	const handleClick = (id) => {
		navigate(`/dashboard/start/${id}`);
	};

	const handleResumeClick = (id, attempt, time, title) => {
		navigate(`/test/${id}/${attempt}/${time}/${title}/resume`);
	};

	const TestList = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(api_url + "get_test_list", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setQuiz(result.data);
				if (result.data.length === 0) {
					setnTest(false);
				} else {
					setnTest( true );
				}
			})
			.catch((error) => console.log("error", error));
	};

	const ResumeTest = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(api_url + "get_resume_test_list", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setResumeQuiz(result.data);
				if (result.data.length === 0) {
					setrTest(false);
				} else {
					setrTest( true );
				}
			})
			.catch((error) => console.log("error", error));
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			TestList();
			ResumeTest();
			console.log( "refresh" );
		}, 5000);

		return () => clearInterval(intervalId);
	}, [lang]);

	useEffect(() => {
		if (!token && !authenticate) {
			navigate("/");
		} else {
			setIsLoading(false);
			TestList();
			ResumeTest();
		}
	}, [token, authenticate, lang]);

	if (isLoading) {
		return (
			<div className="text-center">
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: "100vh" }}
				>
					<Spinner
						animation="border"
						role="status"
						style={{ width: "5rem", height: "5rem" }}
					></Spinner>
				</div>
			</div>
		);
	}

	return (
		<div>
			<NavigationBar hide={false} show={true} />{" "}
			<div fluid className="dashboard">
				<h1>
					{t("career assessment")} {t("tests")}
				</h1>
				<div className="tests">
					{nTest === true || rTest === true ? (
						""
					) : (
						<div className="no-test">{t("no test")}</div>
					)}
					{rTest && resumeQuiz && resumeQuiz.length > 0 ? (
						
							resumeQuiz.map((n, index) => (
								<div
									key={index}
									className="test"
									onClick={() =>
										handleResumeClick(
											n.test_id,
											n.test_credits[n.test_credits.length - 1].attempt_id,
											n["allowed_time(min)"],
											n.test_title
										)
									}
								>
									<h3>{n.test_title}</h3>{" "}
									<h4 style={{ color: "orange" }}>
										{n.test_status === "Not Completed"
											? t("resumed")
											: t("completed")}
									</h4>
								</div>
							))
						
					) : null}

					{nTest && quiz
						? quiz.length > 0
							? quiz.map((n, index) => (
									<div
										key={index}
										className="test"
										onClick={() => handleClick(n.test_id)}
									>
										<h3>{n.test_title}</h3>
									</div>
							  ))
							: ""
						: ""}
				</div>
			</div>
			{/* <Footer /> */}
		</div>
	);
}
export default Dashboard;

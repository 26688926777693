import NavigationBar from "../Common/Navbar";
import Footer from "../Common/Footer";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Container, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { api_url } from "../Common/Variable";

function Completed() {
	const [result, setResult] = useState("");
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [lang, setLang] = useState(i18n.language);
	const token = localStorage.getItem("token");
	const authenticate = localStorage.getItem("authenticate");
	const [isLoading, setIsLoading] = useState(true);
	const [empty, setEmpty] = useState(false);

	useEffect(() => {
		setLang(i18n.language);
	}, [i18n.language]);

	const TestResults = () => {
		const myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(api_url + "get_completed_test_list", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.data.length === 0) {
					setEmpty(false);
				} else {
					setEmpty(true);
				}
				setResult(result.data);
			})
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		if (!token && !authenticate) {
			navigate("/");
		} else {
			setIsLoading(false);
			TestResults();
		}
	}, [token, authenticate, lang]);

	if (isLoading) {
		return (
			<div className="text-center">
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: "100vh" }}
				>
					<Spinner
						animation="border"
						role="status"
						style={{ width: "5rem", height: "5rem" }}
					></Spinner>
				</div>
			</div>
		);
	}

	const convertDateFormat = (apiDate) => {
		const parts = apiDate.split(" ");

		const dateParts = parts[0].split("-");
		const day = parseInt(dateParts[0], 10);
		const month = parseInt(dateParts[1], 10);
		const year = parseInt(dateParts[2], 10);

		const timeParts = parts[1].split(":");
		let hours = parseInt(timeParts[0], 10);
		const minutes = parseInt(timeParts[1], 10);
		const seconds = parseInt(timeParts[2], 10);

		const meridiem = parts[2];

		if (meridiem === "pm") {
			hours += 12;
		}

		const formattedDate = new Date(
			year,
			month - 1,
			day,
			hours,
			minutes,
			seconds
		);

		const options = {
			day: "numeric",
			month: "short",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: true,
		};

		return formattedDate.toLocaleString("en-US", options);
	};

	return (
		<div>
			<NavigationBar hide={false} show={true} />

			<div className="completed">
				<h1>{t("Test Results")}</h1>
				<div className="results">
					{empty === true ? "" : ""}
					{empty && result
						? result.length > 0
							? result.map((n, index) => (
									<div>
										<h2 className="credits">
											{t("credit")} {index + 1}
										</h2>
										<div className="credit-group">
											{n.credit_test_details.map((testDetail, idx) => (
												<div className="result" key={idx}>
													<h2>{testDetail.test_title}</h2>
													<div className="report">
														<strong>{t("ttime")}:</strong>
														<span>
															&nbsp;{testDetail["test_allowed_time(min)"]}
														</span>
														<span>&nbsp;{t("min")}</span>
													</div>
													<div className="report">
														<strong>{t("tq")}:</strong>
														<span>&nbsp;{testDetail.test_total_questions}</span>
													</div>
													<div className="report">
														<strong>{t("ta")}:</strong>
														<span>
															&nbsp;{testDetail.test_attempted_questions}
														</span>
													</div>
													{/* <div className="report">
														<strong>{t("percentage")}:</strong>
														<span>
															&nbsp;{testDetail["test_attempted_percentage(%)"]}
														</span>
													</div> */}
													<div className="report">
														<strong>{t("tstatus")}:</strong>
														<span>&nbsp;{testDetail.test_status}</span>
													</div>
													<div className="report">
														<strong>{t("stime")}:</strong>
														<span>
															&nbsp;{convertDateFormat(testDetail.test_started)}
														</span>
													</div>
													<div className="report">
														<strong>{t("etime")}:</strong>
														<span>&nbsp;{convertDateFormat(testDetail.test_ended)}</span>
													</div>
												</div>
											))}
											<br />
										</div>
										<br />
									</div>
							  ))
							: ""
						: ""}
				</div>
			</div>
		</div>
	);
}

export default Completed;
